import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import { reach } from "yup";

import PrivateRoute from "./PrivateRoute";

// lazy load all the views
const Pruebas = React.lazy(() => import("../pages/pruebas"));
//#region Dashboard general, graficas variación contratos y alertas de control interno y operaciones
//Dashboard
const Dashboard = React.lazy(() =>
  import("../pages/reportes/dashboard/index.js")
);

//Dashboard 2
const Dashboard2 = React.lazy(() =>
  import("../pages/reportes/DashBoardGraficos/DashBoard.js")
);

const DashboardAlertas = React.lazy(() =>
  import("../pages/reportes/DashBoardAlertas/index.js")
);

const AlertasControlInterno = React.lazy(() => import("../pages/reportes/AlertasControlInterno/index.js"))
//#endregion

//Reportes
//#region Reportes Contratos
const ReporteContratos = React.lazy(() =>
  import("../pages/reportes/Contratos")
);
const ReporteProrrogas = React.lazy(() =>
  import("../pages/reportes/Prorrogas")
);
const ReporteProrrogasBI = React.lazy(() =>
  import("../pages/reportes/ProrrogasBI")
);
const ReporteContratosVencerse = React.lazy(() =>
  import("../pages/reportes/ContratosVencerse")
);
//#endregion
//#region Reportes Almacén
const ReporteVentas = React.lazy(() => import("../pages/reportes/Ventas"));
const ReporteIngresos = React.lazy(() => import("../pages/reportes/Ingresos"));
const ReporteEgresos = React.lazy(() => import("../pages/reportes/Egresos"));
const ReporteDevolucionVentas = React.lazy(() => import("../pages/reportes/DevolucionVentas"));
const ReporteCompras = React.lazy(() => import("../pages/reportes/Compras"));
const ReporteOrdenesVenta = React.lazy(() => import("../pages/reportes/OrdenesVenta/index.jsx"));

//#endregion
//#region Reportes Clientes
const ReporteConsolidadoClientes = React.lazy(() => import("../pages/reportes/ConsolidadoClientes"));
const ReporteCliente = React.lazy(() => import("../pages/reportes/Clientes"));
const ReporteEstadisticaClientes = React.lazy(() => import("../pages/reportes/EstadisticaClientes"));
const ReporteAnalisisClientes = React.lazy(() => import("../pages/reportes/AnalisisClientes"));
const ReporteDistribucionClientesMap = React.lazy(() => import("../pages/distribucionMapas/Clientes/index.jsx"));
const ReporteUltimosMovimientosClientes = React.lazy(() => import("../pages/reportes/UltimosMovimientos/index.jsx"));
//#endregion
//#region Reportes Lista de control
const ReporteBitacoraListaControl = React.lazy(() =>
  import("../pages/reportes/BitacoraListaControl")
);
//#endregion
//#region Especializados
const ReporteGraphMovimientoConsolidado = React.lazy(() =>
  import("../pages/reportes/GraphMovimientoConsolidado.js")
);
const ReporteMovConso = React.lazy(() => import("../pages/reportes/MovConso"));
//#endregion

//#region Sincronizacion
const ReporteSynchronization = React.lazy(() =>
  import("../pages/reportes/synchronization/index.js"));

const ReporteLogBi = React.lazy(() => import("../pages/reportes/Administracion/LogBi/index.jsx"));
//#endregion
//#region Reportes Caja
const ReporteSaldoCaja = React.lazy(() =>
  import("../pages/reportes/SaldosCaja/SaldoCaja.jsx")
);

const ReporteSaldoCajaPredictivo = React.lazy(() =>
  import("../pages/reportes/SaldosCaja/SaldosCajaPredictivo.jsx")
);
//#endregion
//#region Reportes Plan Separe
const ReportePlanSepare = React.lazy(() =>
  import("../pages/reportes/PlanSepare")
);
//#endregion
//#region Reportes Usuarios
const ReporteUsuariosAcv = React.lazy(() => import("../pages/reportes/Usuarios/Usuarios"));
//#endregion
//#region Reportes Inventario
const ReporteInventarioAlmacen = React.lazy(() =>
  import("../pages/reportes/InventarioAlmacen")
);
//Inventario
const DistribucionInventario = React.lazy(() =>
  import("../pages/Inventarios/DistribucionInventario")
);

const ArticulosInventario = React.lazy(() =>
  import("../pages/Inventarios/Inventario.js")
);

const InventarioMap = React.lazy(() =>
  import("../pages/Inventarios/InventarioMap")
);

const TrasladoInventarioEntrada = React.lazy(() => import("../pages/reportes/TrasladosInventariosEntrada/index.jsx"));

const TrasladoInventarioSalida = React.lazy(() => import("../pages/reportes/TrasladosInventariosSalida/index.jsx"));

//#endregion

//#region Reportes con mapas
const ReporteGeoreferenciaVisita = React.lazy(() => import("../pages/ReportesMapas/RevisionDiaria/index.jsx"));
//#endregion


//#region Maestros
const AppSucursal = React.lazy(() =>
  import("../pages/maestros/sucursal/index.js")
);
const AppMensajes = React.lazy(() =>
  import("../pages/maestros/mensajes/index.js")
);
const AppListasControl = React.lazy(() =>
  import("../pages/maestros/listasControl/index.js")
);
const AppTipoAlerta = React.lazy(() =>
  import("../pages/maestros/tipoAlerta/index.js")
);
const AppIngreso = React.lazy(() =>
  import("../pages/maestros/ingresos/index.js")
);
const AppEgreso = React.lazy(() =>
  import("../pages/maestros/egresos/index.js")
);

const AppZona = React.lazy(() =>
  import("../pages/maestros/zonas/index.js")
);

const AppIndicador = React.lazy(() =>
  import("../pages/maestros/indicadores/index.js")
);

const AppMaestroTareas = React.lazy(() => import("../pages/maestros/tareas/index.jsx"));
//#endregion
//Maestros
//#region  Transacciones
const TransaccionPagoProrrogasBI = React.lazy(() =>
  import("../pages/Transacciones/PagoProrrogasBI")
);
const TransaccionBloquearCliente = React.lazy(() =>
  import("../pages/Transacciones/BloquearCliente")
);
const TransaccionListaControlLocal = React.lazy(() =>
  import("../pages/Transacciones/ListaControlLocal/index.js")
);
const TransaccionIngresoEgreso = React.lazy(() =>
  import("../pages/Transacciones/IngresoEgreso")
);
const TransaccionCuadrarCaja = React.lazy(() =>
  import("../pages/Transacciones/CuadreCaja")
);

const TransaccionAbonoPlanSepareBI = React.lazy(() => import("../pages/Transacciones/PlanSepare/index.jsx"));

const TransaccionAsignarTareasXRol = React.lazy(() => import("../pages/Transacciones/TareasPorRol/index.jsx"));

//#endregion

// auth
const Login = React.lazy(() => import("../pages/account/Login"));
const Logout = React.lazy(() => import("../pages/account/Logout"));
const Register = React.lazy(() => import("../pages/account/Register"));
const Registrar = React.lazy(() => import("../pages/account/Registrar"));
const Confirm = React.lazy(() => import("../pages/account/Confirm"));
const ForgetPassword = React.lazy(() =>
  import("../pages/account/ForgetPassword")
);
const LockScreen = React.lazy(() => import("../pages/account/LockScreen"));
const Usuarios = React.lazy(() => import("../pages/account/users/index.js"));
const Roles = React.lazy(() => import("../pages/account/roles/index.js"));
// pages
const ErrorPageNotFound = React.lazy(() =>
  import("../pages/error/PageNotFound")
);
const ErrorPageNotFoundAlt = React.lazy(() =>
  import("../pages/error/PageNotFoundAlt")
);
const ServerError = React.lazy(() => import("../pages/error/ServerError"));
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));

// root routes
//Este es el predeterminado
const rootRoute = {
  path: "/",
  exact: true,
  component: () => <Redirect to="/dashboard" />,
  route: PrivateRoute,
};

//Dashboard2
const dashboard2 = {
  path: "/reportes/DashBoard2",
  name: "DashBoard2",
  route: PrivateRoute,
  icon: "uil-chart",
  component: Dashboard2,
  header: "Reportes",
};

const dashboardAlertas = {
  path: "/reportes/operaciones",
  name: "DashboardOperaciones",
  route: PrivateRoute,
  icon: "uil-chart",
  component: DashboardAlertas,
  header: "Reportes",
}

const alertasControlInterno = {
  path: "/reportes/alertasControlInterno",
  name: "AlertasControlInterno",
  route: PrivateRoute,
  icon: "uil-chart",
  component: AlertasControlInterno,
  header: "Reportes",
}


const pruebasRoutes = {
  path: "/pruebas",
  name: "Pruebas",
  route: PrivateRoute,
  icon: "uil-chart",
  component: Pruebas,
  header: "Pruebas",
};

// routes Dashboards
const dashboardRoute = {
  path: "/dashboard",
  name: "Dashboard",
  route: PrivateRoute,
  icon: "uil-home-alt",
  component: Dashboard,
  header: "Navigation",
};

//routes Reportes
const reporteSaldoCajaRoutes = {
  path: "/reportes/saldosCaja",
  name: "Saldos Caja",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteSaldoCaja,
  header: "Reportes",
};

const reporteSaldoCajaPredictivo = {
  path: "/reportes/saldosCajaPredictivo",
  name: "Saldos Caja Predictivo",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteSaldoCajaPredictivo,
  header: "Reportes",
};

const reporteBitacoraListaControlRoutes = {
  path: "/reportes/bitacoraListaControl",
  name: "Bitacora Lista Control",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteBitacoraListaControl,
  header: "Reportes",
};
const reporteMovimientoConsolidadoRoute = {
  path: "/reportes/movimientosConsolidados",
  name: "Movimientos consolidados",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteMovConso,
  header: "Reportes",
};
const reporteGraphMovimientoConsolidadoRoute = {
  path: "/graficos/movimientosConsolidados",
  name: "Graficos movimiento consolidado",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteGraphMovimientoConsolidado,
  header: "Graficos",
};
const reporteComprasRoute = {
  path: "/reportes/compras",
  name: "Reporte de Compras",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteCompras,
  header: "Reportes",
};
const reporteOrdenesVenta = {
  path: "/reportes/ordenesVenta",
  name: "Reporte de ordenes de venta",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteOrdenesVenta,
  header: "Reportes",
};

const reporteVentasRoute = {
  path: "/reportes/ventas",
  name: "Reporte de Ventas",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteVentas,
  header: "Reportes",
};
const reporteEgresosRoute = {
  path: "/reportes/egresos",
  name: "Reporte de Egresos",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteEgresos,
  header: "Reportes",
};
const reporteIngresosRoute = {
  path: "/reportes/ingresos",
  name: "Reporte de Ingresos",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteIngresos,
  header: "Reportes",
};
const reporteClientesRoute = {
  path: "/reportes/clientes",
  name: "Reporte de Clientes",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteCliente,
  header: "Reportes",
};
const reporteProrrogasRoute = {
  path: "/reportes/prorrogas",
  name: "Reporte de Prorrogas",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteProrrogas,
  header: "Reportes",
};
const reporteDevolucionVentaRoute = {
  path: "/reportes/devolucionVenta",
  name: "Reporte de Devolución Ventas",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteDevolucionVentas,
  header: "Reportes",
};
const reporteContratos = {
  path: "/reportes/contratos",
  name: "Reporte de Contratos",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteContratos,
  header: "Reportes",
};
const reportePlanSepare = {
  path: "/reportes/planSepare",
  name: "Reporte plan separe",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReportePlanSepare,
  header: "Reportes",
};

// #region "Reportes de administración"
const reporteSynchronization = {
  path: "/reportes/sincronizacion",
  name: "Reporte sincronización",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteSynchronization,
  header: "Reportes",
};

const reporteLogBi = {
  path: "/reportes/LogReportes",
  name: "Reporte Log",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteLogBi,
  header: "Reportes",
};
// #endregion

const reporteConsolidadoClientes = {
  path: "/reportes/consolidadocliente",
  name: "Reporte consolidado de clientes",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteConsolidadoClientes,
  header: "Reportes",
};
const reporteInventarioAlmacen = {
  path: "/reportes/inventarioalmacen",
  name: "Reporte inventario almacén",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteInventarioAlmacen,
  header: "Reportes",
};

const reporteEstadisticaClientes = {
  path: "/reportes/estadisticaClientes",
  name: "Estadísticas de clientes",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteEstadisticaClientes,
  header: "Reportes",
};

const reporteProrrogasBI = {
  path: "/reportes/prorrogasBI",
  name: "Reporte prorrogas BI",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteProrrogasBI,
  header: "Reportes",
};

const reporteAnalisisClientes = {
  path: "/reportes/AnalisisClientes",
  name: "Reporte Análisis Clientes",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteAnalisisClientes,
  header: "reportes",
};

const reporteContratosVencerse = {
  path: "/reportes/ContratosVencerse",
  name: "Contratos Vencerse",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteContratosVencerse,
  header: "reportes",
};


const reporteDistribucionClientesMap = {
  path: "/reportes/mapDistribucionClientes",
  name: "Distribución de clientes",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteDistribucionClientesMap,
  header: "reportes",
};

const reporteUltimosMovimientosClientes = {
  path: "/reportes/ultimosMovimientosClientes",
  name: "Ultimos movimientos por cliente",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteUltimosMovimientosClientes,
  header: "reportes",
};
const reporteUsuariosAcv = {
  path: "/reportes/UsuariosAcv",
  name: "Reporte Usuario Acv",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ReporteUsuariosAcv,
  header: "Reportes",
};

const reporteGeoreferenciaVisita = {
  path: "/reportes/revisionDiaria",
  name: "Revisión diaria",
  route: PrivateRoute,
  icon: "",
  component: ReporteGeoreferenciaVisita,
  header: "Georeferenciación"
};

//routes Maestros
const maestroSucursalRoute = {
  path: "/maestro/sucursal",
  name: "Maestro sucursal",
  route: PrivateRoute,
  icon: "uil-chart",
  component: AppSucursal,
  header: "Maestros",
};

const maestroMensajesRoute = {
  path: "/maestro/mensajesSMS",
  name: "Maestro mensajes SMS",
  route: PrivateRoute,
  icon: "uil-chart",
  component: AppMensajes,
  header: "Maestros",
};

const maestroListasControl = {
  path: "/maestro/listasControl",
  name: "Maestro listas de control",
  route: PrivateRoute,
  icon: "uil-chart",
  component: AppListasControl,
  header: "Maestros",
};

const maestroTipoAlerta = {
  path: "/maestro/tipoAlerta",
  name: "Maestro tipo de alerta",
  route: PrivateRoute,
  icon: "uil-chart",
  component: AppTipoAlerta,
  header: "Maestros",
};

const maestroEgreso = {
  path: "/maestro/egreso",
  name: "Maestro egreso",
  route: PrivateRoute,
  icon: "uil-chart",
  component: AppEgreso,
  header: "Maestros",
};

const maestroIngreso = {
  path: "/maestro/ingreso",
  name: "Maestro ingreso",
  route: PrivateRoute,
  icon: "uil-chart",
  component: AppIngreso,
  header: "Maestros",
};

const maestroZona = {
  path: "/maestro/zonas",
  name: "Maestro zonas",
  route: PrivateRoute,
  icon: "uil-chart",
  component: AppZona,
  header: "Maestros",
};

const maestroIndicador = {
  path: "/maestro/indicadores",
  name: "Maestro indicador",
  route: PrivateRoute,
  icon: "uil-chart",
  component: AppIndicador,
  header: "Maestros",
};


const appMaestroTareas = {
  path: "/maestro/parametrizacionTareas",
  name: "Maestro tareas",
  route: PrivateRoute,
  icon: "uil-chart",
  component: AppMaestroTareas,
  header: "Maestros",
};

const distribucionInventario = {
  path: "/inventarios/distribucionInventario",
  name: "Distribución artículos almacén",
  route: PrivateRoute,
  icon: "uil-chart",
  component: DistribucionInventario,
  header: "Inventarios",
};

const articulosInventario = {
  path: "/inventarios/inventario",
  name: "Articulos inventario",
  route: PrivateRoute,
  icon: "uil-chart",
  component: ArticulosInventario,
  header: "Reportes",
};

const inventarioMap = {
  path: "/inventarios/articulosVendidos",
  name: "Inventarios mapa",
  route: PrivateRoute,
  icon: "uil-chart",
  component: InventarioMap,
  header: "Reportes",
};

const trasladoInventarioEntrada = {
  path: "/reportes/trasladoInventarioEntrada",
  name: "Traslado Inventario Entrada",
  route: PrivateRoute,
  icon: "uil-chart",
  component: TrasladoInventarioEntrada,
  header: "Reportes",
};

const trasladoInventarioSalida = {
  path: "/reportes/trasladoInventarioSalida",
  name: "Traslado Inventario Salida",
  route: PrivateRoute,
  icon: "uil-chart",
  component: TrasladoInventarioSalida,
  header: "Reportes",
};




const transaccionPagoProrrogasBI = {
  path: "/Transacciones/PagoProrrogasBI",
  name: "Pago prorrogas SWAT BI",
  route: PrivateRoute,
  icon: "util-chart",
  component: TransaccionPagoProrrogasBI,
  header: "Transacciones",
};


const transaccionBloquearCliente = {
  path: "/Transacciones/BloquearCliente",
  name: "Bloquear cliente",
  route: PrivateRoute,
  icon: "util-chart",
  component: TransaccionBloquearCliente,
  header: "Transacciones",
};

const transaccionListaControlLocal = {
  path: "/Transacciones/bloquearClienteLc",
  name: "Lista control Local",
  route: PrivateRoute,
  icon: "util-chart",
  component: TransaccionListaControlLocal,
  header: "Transacciones",
};

const transaccionIngresoEgreso = {
  path: "/Transacciones/IngresoEgreso",
  name: "Ingreso y egreso",
  route: PrivateRoute,
  icon: "util-chart",
  component: TransaccionIngresoEgreso,
  header: "Transacciones",
}
const transaccionAbonoPlanSepareBI = {
  path: "/Transacciones/abonosPlanSepareBI",
  name: "Abono Plan Separe SWAT BI",
  route: PrivateRoute,
  icon: "util-chart",
  component: TransaccionAbonoPlanSepareBI,
  header: "Transacciones",
};

const transaccionAsignarTareasXRol = {
  path: "/Transacciones/asignarTareasRol",
  name: "Asignar tareas por rol",
  route: PrivateRoute,
  icon: "util-chart",
  component: TransaccionAsignarTareasXRol,
  header: "Transacciones",
};

const registrarRoutes = {
  path: "/account/registrar",
  name: "Registrar",
  component: Registrar,
  route: PrivateRoute,
  header: "Cuenta",
};

const usuariosRoutes = {
  path: "/seguridad/usuarios",
  name: "Usuario",
  component: Usuarios,
  route: PrivateRoute,
  header: "Cuenta",
};

const rolesRoutes = {
  path: "/seguridad/roles",
  name: "Roles",
  component: Roles,
  route: PrivateRoute,
  header: "Cuenta",
};

const transaccionCuadrarCaja = {
  path: "/Transacciones/CuadrarCaja",
  name: "Cuadre de caja",
  route: PrivateRoute,
  icon: "util-chart",
  component: TransaccionCuadrarCaja,
  header: "Cuadre de caja",
};

const reportesRoutes = [
  reporteProrrogasBI,
  reporteSaldoCajaRoutes,
  reporteSaldoCajaPredictivo,
  reporteBitacoraListaControlRoutes,
  reporteMovimientoConsolidadoRoute,
  reporteGraphMovimientoConsolidadoRoute,
  reporteComprasRoute,
  reporteVentasRoute,
  reporteIngresosRoute,
  reporteEgresosRoute,
  reporteClientesRoute,
  reporteProrrogasRoute,
  reporteDevolucionVentaRoute,
  reporteContratos,
  reportePlanSepare,
  reporteSynchronization,
  reporteLogBi,
  reporteConsolidadoClientes,
  reporteInventarioAlmacen,
  reporteEstadisticaClientes,
  transaccionPagoProrrogasBI,
  transaccionBloquearCliente,
  transaccionListaControlLocal,
  transaccionCuadrarCaja,
  reporteAnalisisClientes,
  reporteContratosVencerse,
  dashboard2,
  dashboardAlertas,
  alertasControlInterno,
  articulosInventario,
  inventarioMap,
  reporteDistribucionClientesMap,
  reporteUsuariosAcv,
  reporteUltimosMovimientosClientes,
  reporteGeoreferenciaVisita,
  trasladoInventarioEntrada,
  trasladoInventarioSalida,
  reporteOrdenesVenta
];

const maestrosRoutes = [
  maestroSucursalRoute,
  maestroMensajesRoute,
  maestroListasControl,
  maestroTipoAlerta,
  maestroEgreso,
  maestroIngreso,
  maestroZona,
  maestroIndicador,
  appMaestroTareas
];

const transaccionesRoutes = [
  transaccionPagoProrrogasBI,
  transaccionBloquearCliente,
  transaccionIngresoEgreso,
  transaccionAbonoPlanSepareBI,
  transaccionAsignarTareasXRol
]
const seguridadRoutes = [usuariosRoutes, rolesRoutes, registrarRoutes];

const inventariosRoutes = [distribucionInventario];

// pages
const pageRoutes = {
  path: "/pages/error-404-alt",
  name: "Error - 404-alt",
  component: ErrorPageNotFoundAlt,
  route: PrivateRoute,
};

const otherPublicRoutes = [
  // {
  //     path: '/landing',
  //     name: 'landing',
  //     component: Landing,
  //     route: Route,
  // },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    component: ErrorPageNotFound,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    component: ServerError,
    route: Route,
  },
  {
    path: "/account/confirm",
    name: "Confirm",
    component: Confirm,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// auth
const authRoutes = [
  {
    path: "/account/login",
    name: "Login",
    component: Login,
    route: Route,
  },
  {
    path: "/account/logout",
    name: "Logout",
    component: Logout,
    route: Route,
  },
  {
    path: "/account/register",
    name: "Register",
    component: Register,
    route: Route,
  },
  {
    path: "/account/forget-password",
    name: "Forget Password",
    component: ForgetPassword,
    route: Route,
  },
  {
    path: "/account/lock-screen",
    name: "Lock Screen",
    component: LockScreen,
    route: Route,
  },
];

// All routes
const authProtectedRoutes = [
  rootRoute,
  dashboardRoute,
  ...reportesRoutes,
  ...seguridadRoutes,
  ...maestrosRoutes,
  ...transaccionesRoutes,
  ...inventariosRoutes,
  pruebasRoutes,
  pageRoutes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
