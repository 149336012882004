// @flow
import React from "react";
import { Toaster } from "react-hot-toast";
import Routes from "./routes/Routes";

// Themes

// For Saas import Saas.scss
import "./assets/scss/Saas.scss";
// For Dark import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';

// For Modern demo import Modern.scss
// import './assets/scss/Modern.scss';
// For Modern dark demo import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative demo import Creative.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Creative-Dark.scss
// import './assets/scss/Creative-Dark.scss';

type AppProps = {user:"User"};

/**
 * Main app component
 */
const App = (props: AppProps): React$Element<any> => {
  return (
    <>
      <Toaster position="top-right" />
      <Routes/>
    </>
  );
};

export default App;
