import { APICore } from "../../helpers/api/apiCore";
import { toast } from "react-hot-toast";

const api = new APICore();

export async function getRoles() {
  try {
    const response = await toast.promise(api.get("Accounts/getRoles"), {
      loading: "Cargando roles...",
      success: "Roles cargados correctamente",
      error: "Error al cargar los roles",
    });
    if (response.status === 200) {
      return response.data;
    } else {
    }
  } catch (error) {
    toast.error("Error al cargar los roles");
  }
}
export async function getUsers() {
  try {
    const response = await toast.promise(api.get("Accounts/getUsers"), {
      loading: "Cargando usuarios...",
      success: "Usuarios cargados correctamente",
      error: "Error al cargar los usuarios",
    });
    if (response.status === 200) {
      return response.data;
    } else {
    }
  } catch (error) {
  }
}
export async function getRolesSelect(idUser) {
  try {
    const response = await toast.promise(api.get(`Accounts/getRolesSelect/${idUser}`), {
      loading: "Cargando roles...",
      success: "Roles cargados correctamente",
      error: "Error al cargar los roles",
    });
    if (response.status === 200) {
      return response.data;
    } else {
    }
  } catch (error) {
  }
}
export async function getZonasSelect() {
  try {
    const response = await toast.promise(
      api.get("maestro/Zona/getZonasSelect"),
      {
        loading: "Cargando...",
        success: "Zonas cargadas correctamente",
        error: "Error al cargar las Zonas",
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
    }
  } catch (error) {
  }
}
export async function getSucursalesSelect(idZona, idUser) {
  try {
    const response = await toast.promise(
      api.get(`Accounts/getSucursalesSelect/${idZona}/${idUser}`),
      {
        loading: "Cargando sucursales...",
        success: "Sucursales cargadas correctamente",
        error: "Error al cargar las sucursales",
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
    }
  } catch (error) {
  }
}
export async function createUser(data) {
  try {
    const response = await toast.promise(
      api.create("Accounts/createUser", data),
      {
        loading: "Creando usuario...",
        success: "Usuario creado correctamente",
        error: "Error al crear el usuario",
      }
    );
    if (response.status === 200) {
      if (response.data.length > 0) {
        response.data.forEach((element) => {
          toast.error(element.description);
        });
        return false;
      }
      return true;
    } else {
    }
  } catch (error) {
  }
}
export async function updateUser(idUser, data) {
  try {
    const response = await toast.promise(
      api.update(`Accounts/updateUser/${idUser}`, data),
      {
        loading: "Actualizando usuario...",
        success: "Usuario actualizado correctamente",
        error: "Error al actualizar el usuario",
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
    }
  } catch (error) {
  }
}
export async function updateUserPassword(id, data) {
  try {
    const response = await toast.promise(
      api.update("Accounts/updatePassword/" + id, data),
      {
        loading: "Actualizando contraseña...",
        success: "Contraseña actualizada correctamente",
        error: "Error al actualizar la contraseña",
      }
    );
    if (response.status === 200) {
      return response;
    } else {
    }
  } catch (error) {
  }
}
export async function getMenu() {
  try {
    const response = await api.get("Menu/WEB");
    if (response.status === 200) {
      return response.data;
    } else {
    }
  } catch (error) {
  }
}
export function getPermisos() {
  try {
    api
      .get("Accounts/getPermisos")
      .then((response) => {
        if (response.status === 200) {
          sessionStorage.removeItem("PERMISOS");
          sessionStorage.setItem("PERMISOS", JSON.stringify(response.data));
        } else {
        }
      })
      .catch((error) => {
      });
  } catch (error) {
  }
}
export async function getAllMenu() {

  try {
    const response = await api.get(`Menu/getAllMenu`);
    if (response.status === 200) {
      return response.data;
    } else {
    }
  } catch (error) {
  }
}
export async function getAllSubMenu(IdMenu) {

  try {
    const response = await api.get(`Menu/getSubMenu/${IdMenu}`);
    if (response.status === 200) {
      return response.data;
    } else {
    }
  } catch (error) {
  }
}
export async function getAllSubMenuLevel(IdSubMenu) {
  try {
    const response = await api.get(`Menu/getMenuLevel/${IdSubMenu}`);
    if (response.status === 200) {
      return response.data;
    } else {
    }
  } catch (error) {
  }
}
export async function getMenuLevelRol(IdRole, IdSubMenu) {
  try {
    const response = await api.get(`Menu/getMenuLevelRol/${IdRole}/${IdSubMenu}`);
    if (response.status === 200) {
      return response.data;
    } else {
    }
  } catch (error) {
  }
}
export async function createRole(data) {
  try {
    const response = await toast.promise(
      api.create("Accounts/createRoles", data),
      {
        loading: "Creando rol...",
        success: "Rol creado correctamente",
        error: "Error al crear el rol",
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
    }
  } catch (error) {
  }
}
export async function updateRole(id, data) {
  try {
    const response = await toast.promise(
      api.update("Accounts/updateRole/" + id, data),
      {
        loading: "Actualizando rol...",
        success: "Rol actualizado correctamente",
        error: "Error al actualizar el rol",
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
    }
  } catch (error) {
  }
}
export async function saveLog(log) {
  try {
    const response = await
      api.create("LogsSwatBi/Log/SaveLog", log);
    if (response.status === 200) {
      return response;
    } else {
    }
  } catch (error) {
  }
}
