// @flow
import { APICore } from "./apiCore";
import { toast } from "react-hot-toast";

const api = new APICore();

// account
async function login(params: any): any {
  const baseUrl = "/Session/login";
  const response = await toast.promise(api.create(`${baseUrl}`, params), {
    loading: "Iniciando sesión...",
    success: "Sesión iniciada correctamente",
    error: "Error al iniciar sesión",
  });
  return response;
}

function logout(): any {
  const baseUrl = "/Session/logout/";
  return api.create(`${baseUrl}`, {});
}

function signup(params: any): any {
  const baseUrl = "/Accounts/register/";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: any): any {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: any): any {
  const baseUrl = "/password/reset/confirm/";
  return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, forgotPasswordConfirm };
